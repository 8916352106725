import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import { axios } from '../utils/axios';
import { isValidToken, setSession, setUser } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: any;
  };
  [Types.Login]: {
    user: any;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: any;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        axios.defaults.withCredentials = true;
        // const accessToken = window.localStorage.getItem('accessToken');
        console.log('entering here :::');
        const response = await axios.get('/rest/api/user');
        console.log('user details received :::', response);

        // console.log('accessToken', user);
        // if (accessToken && isValidToken(accessToken)) {
        if (response && response.data) {
          // setSession(accessToken);
          // const user = JSON.parse(window.localStorage.getItem('User') || '{}');
          // const user = (await getUserDetails()) || '{}';
          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: response.data.data
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err: any) {
        console.error(err);
        if (err.response.status == 403) {
          refreshTokenCall();
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
        // console.log('-- response from refresh token api --', response);
        // const { responseStatus } = response;
        //   if (response && response.success) {
        //     window.location.reload();
        //   }
        // } else {
        //   dispatch({
        //     type: Types.Initial,
        //     payload: {
        //       isAuthenticated: false,
        //       user: null
        //     }
        //   });
        // }
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post(
      '/rest/api/user/login',
      {
        email,
        password
      },
      { withCredentials: true }
    );
    const { success, data } = response.data;
    if (success != false) {
      // console.log('token here is', token);
      // TODO how to set session here:
      // setSession(token);
      // setUser(JSON.stringify(data));
      // axios.defaults.withCredentials = true;
      dispatch({
        type: Types.Login,
        payload: {
          user: data
        }
      });
    } else {
      throw Error(JSON.stringify(response.data.message));
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  // const logout = async () => {
  //   setSession(null);
  //   setUser('');
  //   dispatch({ type: Types.Logout });
  // };

  const logout = async () => {
    dispatch({
      type: Types.Initial,
      payload: {
        isAuthenticated: false,
        user: null
      }
    });
    try {
      const response = await axios.get('/rest/api/user/logout');
      console.log('response received from logout api::', response);

      if (response && response.status == 200) {
        console.log('entered this check::');
        dispatch({ type: Types.Logout });

        // return response.data;
      }
    } catch (err: any) {
      console.error(err);
      if (err.response.status == 403) {
        console.log('error received from logout api (403)::', err);
        dispatch({ type: Types.Logout }); // this is not working
      } else {
        console.log('error received from logout api (err)::', err);
        dispatch({ type: Types.Logout });
      }
    }
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  const refreshTokenCall = async () => {
    try {
      const response = await axios.get('/rest/api/user/refresh/token');
      console.log('response received in token api ---', response);
      if (response && response.data.success) {
        window.location.reload();
      } else {
        console.log('response received in token api --- +___', response);
        logout();
      }
      // return response.data;
    } catch (err: any) {
      console.log('error received in token api ---', err);
      // if (err.response.status == 403) {
      logout();

      // return err.response.status;
      // }
    }
  };

  const getUserDetails = async () => {
    const response = await axios.get('/rest/api/user');
    return response.data;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        refreshTokenCall,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
